import React from 'react';
import $ from 'jquery';
import './styles.scss';

export default function Avatar({
  imgSrc,
  children,
  iconBorderColor,
  renderDetails
}) {
  const toggleShowDetails = (e) => {
    const ele = $(e.target);
    ele.parents('.avatar').find('.avatar-details').scrollTop(0);
    if (ele.hasClass('avatar')) {
      ele
        .toggleClass('active display')
        .siblings()
        .removeClass('active display');
    } else {
      ele
        .parents('.avatar')
        .toggleClass('display')
        .siblings()
        .removeClass('active display');
      setTimeout(() => ele.parents('.avatar').toggleClass('active'), 1);
    }
  };
  return (
    <div className="avatar" onClick={toggleShowDetails}>
      <div className="avatar-wrapper">
        <div className="avatar-icon-wrapper">
          <div
            className="avatar-icon"
            style={iconBorderColor && { borderColor: iconBorderColor }}
          >
            <img src={imgSrc} alt="Avatar" />
          </div>
        </div>
        <div className="avatar-content-wrapper">{children}</div>
      </div>
      <div className="avatar-details">{renderDetails}</div>
    </div>
  );
}
