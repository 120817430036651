import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import './styles.scss';
import {
  GOOGLE_SUBMISSION_STATUS,
  STATUS_INFO,
  COOKIE_ACCESS_TOKEN
} from '../../constants';
import { isEmpty } from '../../utils/utils';
import {
  fetchCourseWorks,
  // fetchStudents,
  fetchStudentSubmissions,
  checkExpOfAccessToken
} from '../../utils/googleApi';
import { useUser, useDispatchUser, userTypes } from '../../context/UserContext';
// import Calendar from '../../assets/calendar-alt.svg';
import DoughnutChart from '../../components/DoughnutChart';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';

const status = ['NOT_SUBMITTED', 'LATE', 'ON_TIME'];
const currentDate = new Date();
export default function CourseReport() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useUser();
  const dispatchUser = useDispatchUser();
  // const [students, setStudents] = useState([]);
  const [submissionReportData, setSubmissionReportData] = useState([]);
  const [studentSubmissions, setStudentSubmissions] = useState([]);
  const [reportMonth, setReportMonth] = useState(
    currentDate.toISOString().slice(0, 7)
  );
  const [reportMonthController, setReportMonthController] = useState(
    currentDate.toISOString().slice(0, 7)
  );
  // const [currentMonthCW, setCurrentMonthCw] = useState([]);
  const location = useLocation();

  const getUpdatedAccessToken = async () => {
    // will be extracted later
    const verifiedAccessToken = await checkExpOfAccessToken(
      currentUser['accessToken'],
      currentUser['refreshToken']
    );
    // update user info
    if (verifiedAccessToken !== currentUser['accessToken']) {
      dispatchUser({
        type: userTypes.SET_CURRENT_USER,
        payload: {
          ...currentUser,
          accessToken: verifiedAccessToken
        }
      });
    }
    Cookies.set(COOKIE_ACCESS_TOKEN, verifiedAccessToken);
    return verifiedAccessToken;
    //-------//
  };
  const changeMonth = (e) => {
    setReportMonthController(e.target.value);
    console.log(e.target.value);
  };
  const handleSubmit = () => {
    setReportMonth(reportMonthController);
  };

  const getAllSubmissions = async (courseId) => {
    console.log('getAllSubmissions');
    setIsLoading(true);
    try {
      const studentSubmissionList = [];
      const verifiedAccessToken = await getUpdatedAccessToken();
      const resp = await fetchCourseWorks(verifiedAccessToken, courseId);
      // console.log(resp.data);
      if (resp.data.courseWork) {
        const courseWorks = resp.data.courseWork.filter(
          (cW) => cW.creationTime.slice(0, 7) === reportMonthController
        );
        // console.log(courseWorks);

        const submissionPromises = [];
        courseWorks.forEach((cW) => {
          submissionPromises.push(
            fetchStudentSubmissions(verifiedAccessToken, courseId, cW.id)
          );
        });
        const allSubmissions = await Promise.all(submissionPromises);
        allSubmissions.forEach((sub) => {
          if (sub.data) {
            studentSubmissionList.push(sub.data['studentSubmissions']);
          }
        });
      }
      setStudentSubmissions([...studentSubmissionList]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      console.log('Unable to load! Please refresh the page');
      setIsLoading(false);
    }
  };

  const getSubmissionReport = () => {
    // setIsLoading(true);
    const dataObj = {};
    studentSubmissions.forEach((cW) => {
      cW.forEach((sub) => {
        // console.log(sub);
        const hasKey = dataObj.hasOwnProperty(sub.userId);
        if (!hasKey) {
          dataObj[sub.userId] = {
            late: 0,
            onTime: 0,
            notSubmitted: 0,
            total: 0
          };
        }
        if (
          sub.state === GOOGLE_SUBMISSION_STATUS.TURNED_IN ||
          sub.state === GOOGLE_SUBMISSION_STATUS.RETURNED
        ) {
          if (sub.late) {
            dataObj[sub.userId]['late'] += 1;
          } else {
            dataObj[sub.userId]['onTime'] += 1;
          }
        } else {
          dataObj[sub.userId]['notSubmitted'] += 1;
        }
        dataObj[sub.userId]['total'] += 1;
      });
    });
    const dataList = [];
    // console.log(dataObj);
    if (!isEmpty(dataObj)) {
      location.state.students.forEach((student) => {
        // make sure the student is found in the specified month
        if (student.userId in dataObj) {
          const { late, onTime, notSubmitted, total } = dataObj[student.userId];

          const temp = {
            late: Math.round((late / total) * 100),
            onTime: Math.round((onTime / total) * 100),
            notSubmitted: Math.round((notSubmitted / total) * 100),
            total: total,
            profile: student.profile,
            userId: student.userId
          };
          dataList.push(temp);
        }
      });
    }
    dataList.sort((a, b) => {
      if (b.onTime === a.onTime) {
        return b.late - a.late;
      }
      return b.onTime - a.onTime;
    });
    setSubmissionReportData(dataList);
    // setIsLoading(false);
  };
  useEffect(() => {
    getAllSubmissions(location.state.courseId);
    // eslint-disable-next-line
  }, [reportMonth]);

  useEffect(() => {
    getSubmissionReport();
    // eslint-disable-next-line
  }, [studentSubmissions]);
  // get all students
  // useEffect(() => {
  //   let cancelTokenList = [];
  //   const getStudents = async (courseId) => {
  //     try {
  //       // setLoadingStudents(true);
  //       const studentList = [];
  //       const { promise, cancelToken } = await fetchStudents(
  //         currentUser['accessToken'],
  //         courseId
  //       );
  //       cancelTokenList.push(cancelToken);
  //       const resp = await promise;
  //       const studentData = resp.data;
  //       // prevent from no students
  //       if (studentData.students) {
  //         studentList.push(...studentData.students);
  //         let nextPageToken = studentData['nextPageToken'];
  //         // next page
  //         while (nextPageToken) {
  //           const {
  //             promise: promiseInner,
  //             cancelToken: cancelTokenInner
  //           } = fetchStudents(
  //             currentUser['accessToken'],
  //             courseId,
  //             nextPageToken
  //           );
  //           cancelTokenList.push(cancelTokenInner);
  //           const respInner = await promiseInner;
  //           studentList.push(...respInner['data']['students']);
  //           nextPageToken = respInner['data']['nextPageToken'];
  //         }
  //       }
  //       setStudents(studentList);
  //       // setLoadingStudents(false);
  //     } catch (error) {
  //       console.log(error);
  //       // setLoadingStudents(false);
  //       // setInfoMsg('Unable to get students of the course! Please try again!');
  //     }
  //   };
  //   getStudents(location.state.courseId);
  //   return () => {
  //     cancelTokenList.forEach((cancelToken) => {
  //       cancelToken &&
  //         cancelToken.cancel('Cancel request(s) of getting students');
  //     });
  //   };
  // }, []);
  return (
    <div className="course-report">
      <div className="top-bar">
        <div className="control">
          <Button className="submit text-btn" onClick={() => history.goBack()}>
            &lt; Back
          </Button>
        </div>
        <div className="headline">
          <h1>Monthly Review</h1>
          <h3>{location.state.courseTitle}</h3>
        </div>
      </div>
      <div className="content">
        <h1>Submission Status</h1>
        <div className="top-bar">
          <div className="filter">
            {status.map((i, idx) => (
              <div key={idx} className="filter-item" onClick={(event) => {}}>
                <span
                  className="color-indicator"
                  style={{ background: STATUS_INFO[i].color }}
                ></span>
                <span className="indicator">{i}</span>
              </div>
            ))}
          </div>
          <div className="date-input-wrapper">
            {/* <img src={Calendar} alt="Date" /> */}
            <input
              id="month-picker"
              type="month"
              value={reportMonthController}
              onChange={changeMonth}
            />
            <Button className="submit text-btn" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
        <div className="data">
          {isLoading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <Spinner />
            </div>
          ) : submissionReportData.length > 0 ? (
            submissionReportData.map((data) => (
              <DoughnutChart
                key={data.userId}
                show1={data.onTime}
                show2={data.late}
                show3={data.notSubmitted}
                label={data.profile.name.fullName}
                style={{
                  '--a1': data.onTime,
                  '--a2': data.late,
                  '--a3': data.notSubmitted
                }}
              />
            ))
          ) : (
            <h2>No Records</h2>
          )}

          {/* <DoughnutChart /> */}
        </div>
      </div>
    </div>
  );
}
