import React from 'react';
import './styles.scss';
import { useGoogleLogout } from 'react-google-login';
// import { COOKIE_REFRESH_TOKEN } from '../../constants';
// Images
import AppName from '../../assets/app-name.png';
import LogoutIcon from '../../assets/logout-icon.png';

import { useUser } from '../../context/UserContext';
import { useCourses } from '../../context/CourseContext';
import { userTypes, useDispatchUser } from '../../context/UserContext';
// import { revokeToken } from '../../utils/googleApi';
// import Cookies from 'js-cookie';
const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID;

export default function Header() {
  const { currentUser } = useUser();
  const { clearCourses } = useCourses();
  const dispatchUser = useDispatchUser();

  const onLogoutSuccess = async () => {
    // const refresh_token = Cookies.get(COOKIE_REFRESH_TOKEN)
    try {
      // await revokeToken(currentUser.accessToken);
      clearCourses();
      console.log('revoked token');
    } catch (error) {
      console.log(error);
    }
    dispatchUser({
      type: userTypes.LOGOUT_USER,
      payload: null
    });
  };
  const onFailure = () => console.log('failed');
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure
  });
  return (
    <div className="header">
      <div className="wrapper">
        <img className="logo" src={AppName} alt="Classroom Guardian" />
        <ul>
          {currentUser && (
            <>
              <li>
                <span className="user-icon-wrapper">
                  <img src={currentUser.pictureUrl} alt="user-icon" />
                </span>
              </li>
              <li>
                <img onClick={signOut} src={LogoutIcon} alt="Logout" />
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
