import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
function Checkbox({ optionValue, labelText, handleCheckboxChange, getCheckboxSetState, defaultChecked = false }) {
  const inputRef = useRef()
  const [isChecked, setIsChecked] = useState(defaultChecked)

  const handleChange = (e) => {
    setIsChecked(prev => !prev)
    handleCheckboxChange(e.target.value, labelText, inputRef)
  }
  useEffect(() => {
    getCheckboxSetState({ labelText, setIsChecked, inputRef })
  }, [getCheckboxSetState, labelText])
  return (
    <div className='checkbox'>
      <label className='checkbox-wrapper'>
        {labelText}
        <input ref={inputRef} type='checkbox' value={optionValue} checked={isChecked} onChange={handleChange} />
        <span className='checkmark'></span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  optionValue: PropTypes.string
}

export default React.memo(Checkbox)
