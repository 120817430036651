import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
export const isEmpty = (obj) => {
  for (let i in obj) {
    return false;
  }
  return true;
};

export const exportAsExcel = ({ jsonData, fileName, sheetName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const fileExtension = '.xlsx';

  const ws = utils.json_to_sheet(jsonData);

  const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };

  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

  const data = new Blob([excelBuffer], { type: fileType });

  saveAs(data, fileName + fileExtension);
};

export const formatISODate = (dateStr) => {
  // const splitDate = dateStr.split('T');
  // const date = splitDate[0]
  // const time = splitDate[1].split('.')[0]
  const dateObj = new Date(dateStr);
  // console.log(dateStr);
  // console.log(dateObj);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString();
  return `${date} ${time}`;
};

export const compareTime = (time1, time2) => {
  return time1 > time2 ? true : false;
};
