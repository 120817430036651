import React, { createContext, useState, useContext } from 'react';

const CourseContext = createContext();

// const initialState = [
//   {
//     "id": "",
//     "name": "",
//     "ownerId": "",
//     "creationTime": "",
//     "updateTime": "",
//     "courseState": "",
//   }
// ]
const initialState = {
  searchButtonRemote: {
    previous: false,
    next: true,
    generateReport: false
  },
  courses: [
    {
      id: '',
      name: '',
      ownerId: '',
      creationTime: '',
      updateTime: '',
      courseState: ''
    }
  ]
};

export function useCourses() {
  return useContext(CourseContext);
}
export function CoursesProvider({ children }) {
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [currentCourse, setCurrentCourse] = useState({});
  const [currentCourseWorks, setCurrentCourseWorks] = useState([]);
  const [courses, setCourses] = useState(initialState['courses']);
  const [loading, setLoading] = useState({
    course: false,
    courseWork: false
  });

  const [showReport, setShowReport] = useState(false);
  const [searchButtonRemote, setSearchButtonRemote] = useState(
    initialState['searchButtonRemote']
  );
  const clearCourses = () => {
    setCourses(initialState['courses']);
    setSelectedCourse([]);
    setCurrentCourseWorks([]);
    setCurrentCourse({});
    setShowReport(false);
    setSearchButtonRemote(initialState['searchButtonRemote']);
    if (document.querySelector('.landing-wrapper')) {
      document.querySelector('.landing-wrapper').classList.remove('active');

      if (document.querySelector('.classroom-coursework')) {
        document
          .querySelector('.classroom-coursework')
          .classList.remove('active');
      }
    }
  };
  return (
    <CourseContext.Provider
      value={{
        courses,
        setCourses,
        clearCourses,
        selectedCourse,
        setSelectedCourse,
        loading,
        setLoading,
        currentCourseWorks,
        setCurrentCourseWorks,
        currentCourse,
        setCurrentCourse,
        showReport,
        setShowReport,
        searchButtonRemote,
        setSearchButtonRemote
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}
