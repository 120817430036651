import React from 'react';
import './styles.scss';
export default function SideBar({ profileImg, children }) {
  return (
    <div className="side-bar">
      <div className="side-bar-wrapper">
        {profileImg && (
          <div className="profile-img">
            <img src={profileImg} alt="Profile icon" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
