import React from 'react';
import './styles.scss';
export default function DoughnutChart({
  label,
  show1 = true,
  show2 = true,
  show3 = true,
  ...props
}) {
  return (
    <div className="doughnut-chart" {...props}>
      {label && <span className="label">{label}</span>}
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="100"
          cy="100"
          className="donut"
          r="80"
          strokeWidth="5"
          stroke={show1 ? 'rgb(117, 229, 151)' : 'transparent'}
          fill="none"
        />
        <circle
          cx="100"
          cy="100"
          className="donut"
          r="80"
          strokeWidth="5"
          stroke={show2 ? 'rgb(229, 184, 117)' : 'transparent'}
          fill="none"
        />

        <circle
          cx="100"
          cy="100"
          className="donut"
          r="80"
          strokeWidth="5"
          stroke={show3 ? 'rgb(229, 117, 117)' : 'transparent'}
          fill="none"
        />
      </svg>
    </div>
  );
}
