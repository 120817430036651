import React, { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import $ from 'jquery';
import Cookies from 'js-cookie';

import { GOOGLE_SUBMISSION_STATUS, STATUS_INFO } from '../../../../constants';
// Components
import PopupMenuButton from '../../../../components/PopupMenuButton';
import Avatar from '../../../../components/Avatar';
import Spinner from '../../../../components/Spinner';
import ItemList from '../../../../components/ItemList';

// Images
// import Calendar from '../../../../assets/calendar.svg';
import FileExcel from '../../../../assets/file-excel.svg';
import ArrowLeft from '../../../../assets/arrow-left.svg';
import Attachment from '../../../../assets/attachment.svg';
import History from '../../../../assets/history.svg';

import {
  fetchStudentSubmissions,
  checkExpOfAccessToken
} from '../../../../utils/googleApi';
import { exportAsExcel, formatISODate } from '../../../../utils/utils';
import {
  useUser,
  useDispatchUser,
  userTypes
} from '../../../../context/UserContext';
import { COOKIE_ACCESS_TOKEN } from '../../../../constants';

const classroomPopupMenuItems = [
  { icon: FileExcel, description: 'Export as Excel' }
  // { icon: Calendar, description: 'Edit Deadline' }
];

const submissionStatus = {
  NOT_SUBMITTED: 'Not Submitted',
  SUBMITTED: 'Submitted'
};
// const GOOGLE_SUBMISSION_STATUS = {
//   SUBMISSION_STATE_UNSPECIFIED: 'SUBMISSION_STATE_UNSPECIFIED',
//   NEW: 'NEW',
//   CREATED: 'CREATED',
//   TURNED_IN: 'TURNED_IN',
//   RETURNED: 'RETURNED',
//   RECLAIMED_BY_STUDENT: 'RECLAIMED_BY_STUDENT'
// };

// const STATUS_INFO = {
//   NOT_SUBMITTED: {
//     name: 'NOT_SUBMITTED',
//     color: '#E57575'
//   },
//   LATE: {
//     name: 'LATE',
//     color: '#E5B875'
//   },
//   ON_TIME: {
//     name: 'ON_TIME',
//     color: '#75E597'
//   },
//   RETURNED: {
//     name: 'RETURNED',
//     color: '#43A7B9'
//   },
//   RESET: {
//     name: 'RESET',
//     color: '#333'
//   }
// };

const status = ['NOT_SUBMITTED', 'LATE', 'ON_TIME', 'RETURNED', 'RESET'];
export default function CourseworkCard({ data, setIsShown }) {
  const { currentUser } = useUser();
  const dispatchUser = useDispatchUser();
  const [studentSubmissions, setStudentSubmissions] = useState([]);
  const [filteredStudentSubmissions, setFilteredStudentSubmissions] = useState(
    []
  );
  const [notSubmitted, setNotSubmitted] = useState([]);
  const [submitted, setSubmitted] = useState([]);
  const [dueDate, setDueDate] = useState('');

  const handleFilter = (event, i) => {
    if (studentSubmissions !== null) {
      const ele = $(event.target);
      $('.coursework-content .filter .indicator').css('font-weight', 'unset');

      ele.parent('.filter-item').length !== 0
        ? ele
            .parent('.filter-item')
            .find('.indicator')
            .css('font-weight', '600')
        : ele.find('.indicator').css('font-weight', '600');

      switch (i) {
        case STATUS_INFO.NOT_SUBMITTED.name:
          setFilteredStudentSubmissions(
            studentSubmissions.filter(
              (sub) => sub.state !== GOOGLE_SUBMISSION_STATUS.TURNED_IN
            )
          );
          return;
        case STATUS_INFO.LATE.name:
          setFilteredStudentSubmissions(
            studentSubmissions.filter(
              (sub) =>
                sub.late && sub.state === GOOGLE_SUBMISSION_STATUS.TURNED_IN
            )
          );
          return;
        case STATUS_INFO.ON_TIME.name:
          setFilteredStudentSubmissions(
            studentSubmissions.filter(
              (sub) => sub.state === GOOGLE_SUBMISSION_STATUS.TURNED_IN
            )
          );
          return;
        case STATUS_INFO.RETURNED.name:
          setFilteredStudentSubmissions(
            studentSubmissions.filter(
              (sub) => sub.state === GOOGLE_SUBMISSION_STATUS.RETURNED
            )
          );
          return;
        default:
          setFilteredStudentSubmissions(studentSubmissions);
          return;
      }
    }
  };
  const classroomWorkPopupMenuHandler = useCallback(
    (index) => {
      switch (index) {
        case 0:
          const fileName = 'classwork-submission-status';
          const sheetName = 'classwork-submission-status';
          let jsonData = [];
          jsonData = [
            ...jsonData,
            ...studentSubmissions.map((i) => {
              return {
                'Class Work Name': data.title,
                'Course Work Type': i.courseWorkType,
                Name: data.students.filter((s) => s.userId === i.userId)[0][
                  'profile'
                ]['name']['fullName'],
                State:
                  i.state === GOOGLE_SUBMISSION_STATUS.TURNED_IN
                    ? 'Submitted'
                    : i.state === GOOGLE_SUBMISSION_STATUS.RETURNED
                    ? 'Returned'
                    : 'Not Submitted',
                'Late Submission': i.late ? 'Yes' : 'No',
                'Update Time': i['submissionHistory'].slice(-1)[0][
                  'stateHistory'
                ]['stateTimestamp']
                  ? formatISODate(
                      i['submissionHistory'].slice(-1)[0]['stateHistory'][
                        'stateTimestamp'
                      ]
                    )
                  : ''
              };
            })
          ];
          exportAsExcel({ fileName, jsonData, sheetName });
          return;
        case 1:
          console.log('Edit Deadline');
          return;
        default:
          console.log('Not found!');
      }
    },
    [data, studentSubmissions]
  );

  useEffect(() => {
    const getUpdatedAccessToken = async () => {
      // will be extracted later
      const verifiedAccessToken = await checkExpOfAccessToken(
        currentUser['accessToken'],
        currentUser['refreshToken']
      );
      // update user info
      if (verifiedAccessToken !== currentUser['accessToken']) {
        dispatchUser({
          type: userTypes.SET_CURRENT_USER,
          payload: {
            ...currentUser,
            accessToken: verifiedAccessToken
          }
        });
      }
      Cookies.set(COOKIE_ACCESS_TOKEN, verifiedAccessToken);
      return verifiedAccessToken;
      //-------//
    };
    // handle due date
    if (data['dueDate']) {
      const day = data['dueDate']['day'];
      const month = data['dueDate']['month'];
      const year = data['dueDate']['year'];
      let hours = 0;
      let minutes = 0;
      if (data['dueTime']['hours']) {
        hours = data['dueTime']['hours'];
        minutes = data['dueTime']['minutes'];
      }
      const date = new Date(`${year}-${month}-${day} ${hours}:${minutes}`);

      setDueDate(date);
    }

    getUpdatedAccessToken()
      .then((verifiedAccessToken) => {
        // get student submissions
        fetchStudentSubmissions(
          verifiedAccessToken,
          data['courseId'],
          data['id']
        )
          .then((resp) => {
            if (resp.data['studentSubmissions']) {
              setStudentSubmissions(resp.data['studentSubmissions']);
            } else {
              setStudentSubmissions(null);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log('Unable to load! Please refresh the page');
          });
      })
      .catch((error) => {
        console.log(error);
        console.log('Unable to load! Please refresh the page');
      });

    // eslint-disable-next-line
  }, [currentUser, data]);

  useEffect(() => {
    // const x = new Date(studentSubmissions[0]['updateTime'])
    // console.log(x.toLocaleTimeString())
    let mounted = true;
    mounted && setFilteredStudentSubmissions(studentSubmissions);
    return () => {
      mounted = false;
    };
  }, [studentSubmissions]);

  useEffect(() => {
    let mounted = true;
    if (
      mounted &&
      studentSubmissions !== null &&
      studentSubmissions.length > 0
    ) {
      setSubmitted(() => {
        const submissions = filteredStudentSubmissions.filter(
          (submission) =>
            submission.state === GOOGLE_SUBMISSION_STATUS.TURNED_IN
        );
        if (submissions.length <= 0) return null;
        const students = data['students'].filter((student) =>
          submissions.some((sub) => student.userId === sub.userId)
        );
        return students.map((student) => ({
          ...student,
          submission: submissions.filter(
            (sub) => sub.userId === student.userId
          )[0]
        }));
      });
      setNotSubmitted(() => {
        const submissions = filteredStudentSubmissions.filter(
          (submission) =>
            submission.state !== GOOGLE_SUBMISSION_STATUS.TURNED_IN
        );
        if (submissions.length <= 0) return null;
        const students = data['students'].filter((student) =>
          submissions.some((sub) => student.userId === sub.userId)
        );
        return students.map((student) => ({
          ...student,
          submission: submissions.filter(
            (sub) => sub.userId === student.userId
          )[0]
        }));
      });
    }
    return () => {
      mounted = false;
    };
  }, [filteredStudentSubmissions, data, studentSubmissions]);
  useEffect(() => {
    $('body').css('overflow', 'hidden');
    return () => {
      $('body').css('overflow', 'initial');
    };
  }, []);
  return (
    <div className="coursework-card">
      <div className="coursework-card-wrapper">
        <div className="top-bar">
          <div
            className="back-btn"
            onClick={() => {
              setIsShown(false);
            }}
          >
            <img src={ArrowLeft} alt="Go Back" />
          </div>
          <div className="coursework-headline">
            <h1>{data['title']}</h1>
            <h6>
              Deadline:{' '}
              {dueDate !== ''
                ? data['dueTime']['hours']
                  ? `${dueDate.toLocaleDateString()} ${dueDate.toLocaleTimeString()}`
                  : `${dueDate.toLocaleDateString()}`
                : 'N/A'}
            </h6>
          </div>
          <div className="popup-menu-btn-wrapper">
            <PopupMenuButton
              handleMenuItemsClick={classroomWorkPopupMenuHandler}
              menuItems={classroomPopupMenuItems}
            />
          </div>
        </div>

        <div className="coursework-content">
          <div className="filter">
            {status.map((i, idx) => (
              <div
                key={idx}
                className="filter-item"
                onClick={(event) => handleFilter(event, i)}
              >
                <span
                  className="color-indicator"
                  style={{ background: STATUS_INFO[i].color }}
                ></span>
                <span className="indicator">{i}</span>
              </div>
            ))}
          </div>
          {studentSubmissions === null ? (
            <div className="coursework-submission-status">
              <h2>No Students Assigned</h2>
            </div>
          ) : (
            <>
              <div className="coursework-submission-status">
                <h2>{submissionStatus.NOT_SUBMITTED}</h2>
                <div className="items">
                  {notSubmitted !== null && notSubmitted.length !== 0
                    ? notSubmitted.map((student) => {
                        // only available when the course work is an assignment
                        const attachments =
                          student.submission.assignmentSubmission.attachments;

                        return (
                          <Avatar
                            key={student.userId}
                            imgSrc={
                              student.profile.photoUrl.includes('https')
                                ? student.profile.photoUrl
                                : `https:${student.profile.photoUrl}`
                            }
                            iconBorderColor={
                              student.submission.state ===
                              GOOGLE_SUBMISSION_STATUS.RETURNED
                                ? STATUS_INFO.RETURNED.color
                                : STATUS_INFO.NOT_SUBMITTED.color
                            }
                            renderDetails={
                              <>
                                <ItemList
                                  header="Attachments"
                                  headerIcon={Attachment}
                                  items={
                                    attachments
                                      ? [...attachments]
                                          .reverse()
                                          .map((att) => att.driveFile.title)
                                      : ['No records']
                                  }
                                />
                                <ItemList
                                  header="History"
                                  headerIcon={History}
                                  items={
                                    student.submission.submissionHistory
                                      ? [
                                          ...student.submission
                                            .submissionHistory
                                        ]
                                          .reverse()
                                          .map(
                                            (his) =>
                                              // contains GradeHistory and StateHistory
                                              his.stateHistory &&
                                              `${formatISODate(
                                                his.stateHistory.stateTimestamp
                                              )}\n${his.stateHistory.state} `
                                          )
                                      : ['No records']
                                  }
                                />
                              </>
                            }
                          >
                            <span>{student.profile.name.fullName}</span>
                            {student.submission.state ===
                              GOOGLE_SUBMISSION_STATUS.RETURNED && (
                              <span className="info">RETURNED</span>
                            )}
                          </Avatar>
                        );
                      })
                    : notSubmitted !== null && <Spinner />}
                </div>
              </div>
              <div className="coursework-submission-status">
                <h2>{submissionStatus.SUBMITTED}</h2>
                <div className="items">
                  {submitted !== null && submitted.length !== 0
                    ? submitted.map((student) => {
                        // only available when the course work is an assignment
                        const attachments =
                          student.submission.assignmentSubmission.attachments;
                        return (
                          <Avatar
                            key={student.userId}
                            imgSrc={
                              student.profile.photoUrl.includes('https')
                                ? student.profile.photoUrl
                                : `https:${student.profile.photoUrl}`
                            }
                            iconBorderColor={
                              student.submission.late
                                ? STATUS_INFO.LATE.color
                                : STATUS_INFO.ON_TIME.color
                            }
                            renderDetails={
                              <>
                                <ItemList
                                  header="Attachments"
                                  headerIcon={Attachment}
                                  items={
                                    attachments
                                      ? [...attachments]
                                          .reverse()
                                          .map((att) => att.driveFile.title)
                                      : ['No records']
                                  }
                                />
                                <ItemList
                                  header="History"
                                  headerIcon={History}
                                  items={
                                    student.submission.submissionHistory
                                      ? [
                                          ...student.submission
                                            .submissionHistory
                                        ]
                                          .reverse()
                                          .map(
                                            (his) =>
                                              // contains GradeHistory and StateHistory
                                              his.stateHistory &&
                                              `${formatISODate(
                                                his.stateHistory.stateTimestamp
                                              )}\n${his.stateHistory.state} `
                                          )
                                      : ['No records']
                                  }
                                />
                              </>
                            }
                          >
                            <span>{student.profile.name.fullName}</span>
                            {(student.submission.assignedGrade ||
                              (attachments &&
                                attachments
                                  .slice(-1)[0]
                                  ['driveFile']['title'].startsWith(
                                    'Edited -'
                                  ))) && <span className="info">Marked</span>}
                          </Avatar>
                        );
                      })
                    : submitted !== null && <Spinner />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
