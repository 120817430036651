import React, { useEffect, useState } from 'react';
import './styles.scss';
import $ from 'jquery';
function PopupMenuButton({ className, menuItems, handleMenuItemsClick }) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const callback = (e) => {
      const ele = $(e.target);
      if (!ele.parents('.popup-menu-btn').length > 0) {
        setIsShown(false);
      }
    };
    let mounted = true;
    let bodyListener = null;
    if (mounted) {
      bodyListener = $('body').on('click', callback);
    }
    return () => {
      mounted = false;
      $('body').off('click', bodyListener);
    };
  }, []);
  return (
    <div className="popup-menu-btn">
      <div
        className={className ? `three-dots ${className}` : 'three-dots'}
        onClick={() => setIsShown((prev) => !prev)}
      ></div>
      {isShown && (
        <div className="popup-menu">
          {menuItems.map((item, index) => (
            <div key={index} onClick={() => handleMenuItemsClick(index)}>
              {item.icon && <img src={item.icon} alt={item.description} />}
              <span>{item.description}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(PopupMenuButton);
