import React from 'react';
import Header from '../components/Header';
export default function HeroLayout({ imageTag, children }) {
  return (
    <div className='hero-layout'>
      <div className='header-wrapper'>
        <Header />
      </div>
      <main className='main'>

        <div className='img-wrapper'>{imageTag}</div>
        <div className='children-wrapper'>
          {children}
        </div>
      </main>
    </div>
  )
}
