import React from 'react';
import './styles.scss';
export default function ItemList({ header, headerIcon, items }) {
  return (
    <div className="item-list">
      <h3>
        {headerIcon && <img src={headerIcon} alt={header} />}
        {header}
      </h3>
      {items.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </div>
  );
}
