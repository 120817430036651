import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './styles.scss';
import ExpandMoreWhite from '../../assets/expand-more-white.svg';
import Spinner from '../Spinner';
export default function SelectMenu({
  selectOptions,
  handleSelectItemClick,
  isLoading
}) {
  const [isShown, setIsShown] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const handleOptionClick = (event, index) => {
    setSelectedItem(event.target.innerText);
    handleSelectItemClick(index);
    setIsShown(false);
  };
  useEffect(() => {
    const callback = (e) => {
      const ele = $(e.target);
      if (!ele.parents('.select-menu').length > 0) {
        setIsShown(false);
      }
    };
    const bodyListener = $('body').on('click', callback);
    setSelectedItem(selectOptions[0]);
    return () => {
      $('body').off('click', bodyListener);
    };
  }, [selectOptions]);

  useEffect(() => {
    console.log('changed');
    setSelectedItem(selectOptions[0]);
    handleSelectItemClick(0);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="select-menu">
      <div
        className="selected-item"
        onClick={() => setIsShown((prev) => !prev)}
      >
        <span>{selectedItem}</span>
        {isLoading ? (
          <Spinner />
        ) : (
          <img
            className={isShown ? 'active' : undefined}
            src={ExpandMoreWhite}
            alt="Toggle to show or hide"
          />
        )}
      </div>
      {isShown && (
        <div className="select-options">
          {selectOptions.map((option, index) => (
            <span
              className={option === selectedItem ? 'active' : undefined}
              key={index}
              onClick={(event) => handleOptionClick(event, index)}
            >
              {option}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}
