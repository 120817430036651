import React from 'react';
import './styles.scss';
export default function Button({ children, className, ...otherProps }) {
  return (
    <button
      className={className ? `button ${className}` : `button`} {...otherProps}>
      {children}
    </button>
  )
}
