import React from 'react';
import './styles.scss';

import CourseWkBg from '../../../../assets/coursewk-bg-example@2x.png';
export default function Card({ overlayTitle, ...otherProps }) {
  return (
    <div className='card' {...otherProps}>
      <div className='card-wrapper'>
        <div className='content'>
          <img src={CourseWkBg} alt='User Icon' />
        </div>
        <div className='overlay'>
          <h1>{overlayTitle}</h1>
        </div>
      </div>
    </div>
  )
}
