import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';

// Layouts
import HeroLayout from './layouts/HeroLayout';
import SideBarLayout from './layouts/SideBarLayout';
// Pages
import Home from './pages/Home';
import CourseReport from './pages/CourseReport';
// Context
import { UserProvider } from './context/UserContext';
import { CoursesProvider } from './context/CourseContext';
// Images
import Classroom from './assets/classroom.jpg';

function App() {
  return (
    <UserProvider>
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <CoursesProvider>
                <HeroLayout
                  imageTag={<img src={Classroom} alt="classroom background" />}
                >
                  <Home />
                </HeroLayout>
              </CoursesProvider>
            )}
          />

          <Route
            path="/course-report"
            render={({ location }) => (
              <SideBarLayout>
                <CourseReport />
              </SideBarLayout>
            )}
          />
        </Switch>
      </Router>
    </UserProvider>
  );
}

export default App;
