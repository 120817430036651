import React, { useContext, useReducer, createContext } from 'react';
import Cookies from 'js-cookie';
import {
  LOCAL_STORAGE_USER,
  COOKIE_REFRESH_TOKEN,
  COOKIE_ACCESS_TOKEN
} from '../constants';

export const userTypes = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  LOGOUT_USER: 'LOGOUT_USER'
};
const UserContext = createContext();

const initialState = {
  currentUser: null
};

function reducer(state, action) {
  switch (action.type) {
    case userTypes.SET_CURRENT_USER:
      localStorage.setItem(
        LOCAL_STORAGE_USER,
        JSON.stringify({ uid: action.payload.uid })
      );
      return {
        ...state,
        currentUser: action.payload
      };
    case userTypes.LOGOUT_USER:
      localStorage.removeItem(LOCAL_STORAGE_USER);
      Cookies.remove(COOKIE_REFRESH_TOKEN);
      Cookies.remove(COOKIE_ACCESS_TOKEN);
      return {
        ...state,
        currentUser: null
      };
    default:
      return state;
  }
}

export function useUser() {
  return useContext(UserContext)['state'];
}

export function useDispatchUser() {
  return useContext(UserContext)['dispatch'];
}
export function UserProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
}
