import axios from 'axios';
import { compareTime } from './utils';
import { mode, currentMode } from '../constants';
export const fetchRefreshToken = (authCode) => {
  const url = 'https://oauth2.googleapis.com/token';
  return axios.post(
    url,
    {
      code: authCode,
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID,
      client_secret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET,
      redirect_uri:
        currentMode === mode.PRD
          ? // ? 'https://fir-hosting-practice-8a078.web.app'
            'https://classroom-guardian.kennethhau.com'
          : 'http://localhost:3000',
      grant_type: 'authorization_code'
    },
    {
      headers: {
        'Content-Type': 'application/json'
        // 'Host': 'oauth2.googleapis.com'
      }
    }
  );
};

export const fetchUserInfo = (accessToken) => {
  console.log('fetchUserInfo');
  const url = `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`;
  return axios.get(url);
};

export const revokeToken = (accessToken) => {
  console.log('revoke token');
  const url = `https://oauth2.googleapis.com/revoke?token=${accessToken}`;
  return axios.post(url);
};

export const fetchTokenInfo = (accessToken) => {
  const url = `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${accessToken}`;
  return axios.get(url);
};

export const refreshToken = (refreshToken) => {
  const url = 'https://oauth2.googleapis.com/token';
  console.log('Refresh a token!');
  return axios.post(
    url,
    {
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID,
      client_secret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    },
    {
      headers: {
        'Content-Type': 'application/json'
        // 'Host': 'oauth2.googleapis.com'
      }
    }
  );
};

export const fetchCourses = (accessToken) => {
  console.log('fetchCourses');
  // const url = `https://classroom.googleapis.com/v1/courses?key=${process.env.REACT_APP_GOOGLE_CLASSROOM_API_KEY}`;
  const url = `https://classroom.googleapis.com/v1/courses?courseStates=ACTIVE`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });
};

export const fetchCourseWorks = (accessToken, courseId) => {
  const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork?orderBy=updateTime%20desc`;
  // const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork?orderBy=updateTime%20desc&key=${process.env.REACT_APP_GOOGLE_CLASSROOM_API_KEY}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });
};
export const fetchStudents = (accessToken, courseId, nextPageToken) => {
  let url = null;
  if (nextPageToken) {
    url = `https://classroom.googleapis.com/v1/courses/${courseId}/students?pageToken=${nextPageToken}`;
    // url = `https://classroom.googleapis.com/v1/courses/${courseId}/students?pageToken=${nextPageToken}&key=${process.env.REACT_APP_GOOGLE_CLASSROOM_API_KEY}`;
  } else {
    url = `https://classroom.googleapis.com/v1/courses/${courseId}/students`;
    // url = `https://classroom.googleapis.com/v1/courses/${courseId}/students?key=${process.env.REACT_APP_GOOGLE_CLASSROOM_API_KEY}`;
  }

  const cancelToken = axios.CancelToken.source();
  return {
    promise: axios.get(url, {
      cancelToken: cancelToken.token,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
      }
    }),
    cancelToken
  };
};
export const fetchStudentSubmissions = (
  accessToken,
  courseId,
  courseWorkId
) => {
  const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions`;
  // const url = `https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions?key=${process.env.REACT_APP_GOOGLE_CLASSROOM_API_KEY}`;
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json'
    }
  });
};

export const checkExpOfAccessToken = async (accessToken, refreshToken_) => {
  console.log('checkExpOfAccessToken');
  let validAccessToken = accessToken;
  let isError = false;
  let isExpired = false;
  try {
    const resp = await fetchTokenInfo(accessToken);
    const currentTime = new Date();
    isExpired = !compareTime(resp.data.exp, currentTime.getTime() / 1000);
  } catch (error) {
    console.log(error);
    isError = true;
  }

  if (isExpired || isError) {
    console.log('isExpired or error');
    const resp = await refreshToken(refreshToken_);
    if (resp.data['access_token']) {
      validAccessToken = resp.data['access_token'];
    }
  }

  return validAccessToken;
};

// export const googleLogin = () => {
//   const scope = 'https://www.googleapis.com/auth/classroom.courses.readonly' +
//     ' https://www.googleapis.com/auth/classroom.coursework.students.readonly' +
//     ' https://www.googleapis.com/auth/classroom.rosters.readonly';
//   const url = `https://accounts.google.com/o/oauth2/v2/auth?
//   scope=https://www.googleapis.com/auth/drive.metadata.readonly&
//   include_granted_scopes=true&
//   response_type=token&
//   state=state_parameter_passthrough_value&
//   redirect_uri='http://localhost:3000/'&
//   client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID}`
//   return axios.get(url)
// }
