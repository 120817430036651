import React, { useEffect, useState, useMemo } from 'react';
import './styles.scss';
import Cookies from 'js-cookie';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';

import { COOKIE_REFRESH_TOKEN, COOKIE_ACCESS_TOKEN } from '../../constants';
// Context
import { useCourses } from '../../context/CourseContext';
// Components
import Login from '../../components/Login';
import Autocomplete from '../../components/Autocomplete';
import Button from '../../components/Button';
import PopupMenuButton from '../../components/PopupMenuButton';
import SelectMenu from '../../components/SelectMenu';
import DisplayCard from './components/DisplayCard';
import CourseworkCard from './components/CourseworkCard';
import Snackbar from '../../components/Snackbar';
// Images
import AppName from '../../assets/app-name.png';
import Calendar from '../../assets/calendar.svg';
// import FileExcel from '../../assets/file-excel.svg';
// Custom Hooks
// import useResizeListener from '../../customHooks/useResizeListener';

import { isEmpty } from '../../utils/utils';
import {
  fetchUserInfo,
  // refreshToken,
  fetchCourses,
  fetchCourseWorks,
  fetchStudents,
  checkExpOfAccessToken
} from '../../utils/googleApi';
import { userTypes, useDispatchUser, useUser } from '../../context/UserContext';
import { fetchUserFromFB } from '../../firebase/utils';

const classroomPopupMenuItems = [
  { icon: Calendar, description: 'Monthly Review' }
];
// const initialCourseWorks = [{
//   assigneeMode: "",
//   courseId: "",
//   creationTime: "",
//   creatorUserId: "",
//   id: "",
//   maxPoints: 0,
//   state: "",
//   submissionModificationMode: "",
//   title: "",
//   updateTime: "",
//   workType: ""
// }]

const updateClassroomCourseWorkPOS = () => {
  const landingWrapper = $('.landing-wrapper');
  if (!landingWrapper.hasClass('active')) {
    landingWrapper.addClass('active');
    landingWrapper.on('transitionend', () => {
      const winInnerHeight = $(window).innerHeight();
      const landingWrapperOffset = landingWrapper.offset().top;
      const landingWrapperInnerHeight = landingWrapper.innerHeight();
      $('.classroom-coursework').addClass('active');
      $('.classroom-coursework').css(
        '--yPOS',
        `-${
          winInnerHeight -
          (landingWrapperOffset + landingWrapperInnerHeight + 30)
        }px`
      );
    });
  }
};
export default function Home() {
  const [errorMsg, setErrorMsg] = useState('');
  const [infoMsg, setInfoMsg] = useState('');
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [verifyingExistingUser, setVerifyingExistingUser] = useState(false);
  const [selectedCoursework, setSelectedCoursework] = useState([]);
  const {
    courses,
    setCourses,
    selectedCourse,
    setSelectedCourse,
    loading,
    setLoading,
    currentCourseWorks,
    setCurrentCourseWorks,
    currentCourse,
    setCurrentCourse,
    showReport,
    setShowReport,
    clearCourses,
    searchButtonRemote,
    setSearchButtonRemote
  } = useCourses();
  const [courseWorks, setCourseWorks] = useState([]);
  const { currentUser } = useUser();
  const dispatchUser = useDispatchUser();
  const [showCourseWork, setShowCourseWork] = useState({
    isShown: false,
    title: '',
    id: ''
  });

  const getUpdatedAccessToken = async () => {
    // will be extracted later
    const verifiedAccessToken = await checkExpOfAccessToken(
      currentUser['accessToken'],
      currentUser['refreshToken']
    );
    // update user info
    if (verifiedAccessToken !== currentUser['accessToken']) {
      dispatchUser({
        type: userTypes.SET_CURRENT_USER,
        payload: {
          ...currentUser,
          accessToken: verifiedAccessToken
        }
      });
    }
    Cookies.set(COOKIE_ACCESS_TOKEN, verifiedAccessToken);
    return verifiedAccessToken;
    //-------//
  };
  const history = useHistory();
  const computedSelectedCourse = useMemo(() => {
    return selectedCourse.map((course) => course.label);
  }, [selectedCourse]);

  const classroomSelectMenuHandler = (index) => {
    setCurrentCourse(selectedCourse[index]);
  };

  const classroomPopupMenuHandler = (index) => {
    switch (index) {
      case 0:
        console.log('Monthly Submission Review');
        history.push(`/course-report/${currentCourse.value}`, {
          courseId: currentCourse.value,
          courseTitle: currentCourse.label,
          students: students
        });
        break;
      default:
        return;
    }
  };
  const handleCourseWorkClick = (event, courseWorks) => {
    // console.log(courseWorks)
    setShowCourseWork({
      ...courseWorks,
      isShown: true,
      students
    });
  };

  const clickPrevious = () => {
    setErrorMsg('');
    setShowReport(false);
    setSearchButtonRemote((prev) => ({
      ...prev,
      previous: false,
      next: true,
      generateReport: false
    }));
  };

  // const clickMonthlyReview = () => {
  //   if (selectedCourse.length === 0) {
  //     setErrorMsg('Select one course only for monthly review!');
  //     return;
  //   }
  //   setErrorMsg('');
  //   history.push(`/course-report/${currentCourse.value}`, {
  //     courseId: currentCourse.value,
  //     courseTitle: currentCourse.label,
  //     students: students
  //   });
  // };
  // Courseworks
  const clickNext = async () => {
    if (selectedCourse.length === 0) {
      setErrorMsg('Please select course!');
      return;
    }
    setLoading((prev) => ({ ...prev, courseWork: true }));
    setErrorMsg('');
    setCourseWorks([]);
    setSelectedCoursework([]);
    const verifiedRefreshToken = await getUpdatedAccessToken();

    const promises = [];
    if (selectedCourse.length === 1 && selectedCourse[0]['value'] === 'All') {
      courses.forEach((course) => {
        promises.push(fetchCourseWorks(verifiedRefreshToken, course.id));
      });
    } else {
      selectedCourse.forEach((course) => {
        promises.push(fetchCourseWorks(verifiedRefreshToken, course.value));
      });
    }
    Promise.all(promises).then((resps) => {
      console.log('loading course works');
      resps.forEach((resp) => {
        const onlyAssignments = resp.data['courseWork'].filter(
          (courseWork) => courseWork.workType === 'ASSIGNMENT'
        );
        setCourseWorks((prev) => {
          return [...prev, ...onlyAssignments];
        });
      });
      setLoading((prev) => ({ ...prev, courseWork: false }));
    });
    setSearchButtonRemote((prev) => ({
      ...prev,
      previous: true,
      next: false,
      generateReport: true
    }));
  };

  const clickGenerateReport = () => {
    setInfoMsg('');
    if (selectedCoursework.length === 0) {
      setErrorMsg('Please select course work!');
      return;
    }
    setErrorMsg('');
    $();
    // selected All courses
    if (selectedCourse.length === 1 && selectedCourse[0]['label'] === 'All') {
      setSelectedCourse(
        courses.map((course) => ({ label: course.name, value: course.id }))
      );
    }
    let detailedCW = [];
    // selected All courseworks
    if (
      selectedCoursework.length === 1 &&
      selectedCoursework[0]['label'] === 'All'
    ) {
      detailedCW = [...courseWorks];
    } else {
      selectedCoursework.forEach((cw) => {
        detailedCW = [
          ...detailedCW,
          ...courseWorks.filter((c) => c.id === cw.value)
        ];
      });
    }
    setCurrentCourseWorks(detailedCW);
    setShowReport(true);
    updateClassroomCourseWorkPOS();
    setInfoMsg(`Successfully generated report!`);
  };

  // Login
  useEffect(() => {
    const login = async () => {
      setVerifyingExistingUser(true);
      const refreshTokenValue = Cookies.get(COOKIE_REFRESH_TOKEN);
      // console.log(refreshTokenValue);

      if (refreshTokenValue) {
        try {
          let accessToken = Cookies.get(COOKIE_ACCESS_TOKEN);
          accessToken = await checkExpOfAccessToken(
            accessToken,
            refreshTokenValue
          );
          // console.log(accessToken);
          Cookies.set(COOKIE_ACCESS_TOKEN, accessToken);
          const profile = await fetchUserInfo(accessToken);
          const uid = profile.data['id'];
          const userProfile = await fetchUserFromFB(uid);
          if (userProfile) {
            dispatchUser({
              type: userTypes.SET_CURRENT_USER,
              payload: {
                uid,
                ...userProfile,
                accessToken,
                refreshToken: refreshTokenValue
              }
            });
          }
          setVerifyingExistingUser(false);
          setLoading((prev) => ({ ...prev, course: true }));
          const courses = await fetchCourses(accessToken);
          const coursesList = courses['data']['courses'].map((course) => ({
            id: course.id,
            name: course.name,
            ownerId: course.ownerId,
            creationTime: course.creationTime,
            updateTime: course.updateTime,
            courseState: course.courseState
          }));
          // setSearchButtonRemote(initialState['searchButtonRemote'])
          clearCourses();
          setCourses(coursesList);
          setLoading((prev) => ({ ...prev, course: false }));
        } catch (error) {
          setVerifyingExistingUser(false);
          setInfoMsg('Unable to proceed! Please login again!');
          console.log(error);
          console.log('Home');
        }
      } else {
        setVerifyingExistingUser(false);
      }
    };
    login();
    // eslint-disable-next-line
  }, []);

  // get students of the course
  useEffect(() => {
    let cancelTokenList = [];
    const getStudents = async () => {
      try {
        if (!isEmpty(currentCourse)) {
          setLoadingStudents(true);
          const studentList = [];
          console.log('getting student');
          const verifiedAccessToken = await getUpdatedAccessToken();
          const { promise, cancelToken } = await fetchStudents(
            verifiedAccessToken,
            currentCourse.value
          );
          cancelTokenList.push(cancelToken);
          const resp = await promise;
          console.log('changed course');
          const studentData = resp.data;
          // prevent from no students
          if (studentData.students) {
            studentList.push(...studentData.students);
            let nextPageToken = studentData['nextPageToken'];
            // next page
            while (nextPageToken) {
              const {
                promise: promiseInner,
                cancelToken: cancelTokenInner
              } = fetchStudents(
                currentUser['accessToken'],
                currentCourse.value,
                nextPageToken
              );
              cancelTokenList.push(cancelTokenInner);
              const respInner = await promiseInner;
              studentList.push(...respInner['data']['students']);
              nextPageToken = respInner['data']['nextPageToken'];
            }
          }
          setStudents(studentList);
          setLoadingStudents(false);
        }
      } catch (error) {
        console.log(error);
        setLoadingStudents(false);
        setInfoMsg('Unable to get students of the course! Please try again!');
      }
    };
    getStudents();

    return () => {
      // clear
      cancelTokenList.forEach((cancelToken) => {
        cancelToken &&
          cancelToken.cancel('Cancel request(s) of getting students');
      });
    };
    // eslint-disable-next-line
  }, [currentCourse]);
  return (
    <div className="home">
      {infoMsg && <Snackbar message={infoMsg} setMessage={setInfoMsg} />}
      <section className="landing">
        <div className="landing-wrapper">
          <div className="landing-header">
            <h1 className="app-name">
              <img src={AppName} alt="Classroom Guardian" />
            </h1>
            <h6 className="app-description">A handy Google Classroom tool</h6>
          </div>

          {!currentUser ? (
            <Login isLoading={verifyingExistingUser} />
          ) : (
            <div className="search-bar">
              {errorMsg && <h6 className={`error-msg`}>{errorMsg}</h6>}
              {searchButtonRemote['next'] && (
                <Autocomplete
                  seacrhFieldLabel="Select Course"
                  options={courses.map((course) => ({
                    value: course.id,
                    label: course.name
                  }))}
                  selectedOptions={selectedCourse}
                  setSelectedOptions={setSelectedCourse}
                  isLoading={loading['course']}
                />
              )}
              {searchButtonRemote['generateReport'] && (
                <Autocomplete
                  seacrhFieldLabel="Select Coursework"
                  options={courseWorks.map((coursework) => ({
                    value: coursework.id,
                    label: coursework.title
                  }))}
                  selectedOptions={selectedCoursework}
                  setSelectedOptions={setSelectedCoursework}
                  isLoading={loading['courseWork']}
                />
              )}
              {searchButtonRemote['previous'] && (
                <Button
                  className="text-btn"
                  style={{ color: '#333' }}
                  onClick={clickPrevious}
                  disabled={loading['courseWork'] || loadingStudents}
                >
                  Previous
                </Button>
              )}
              {searchButtonRemote['next'] && (
                <>
                  <Button
                    className="text-btn"
                    style={{ color: '#333' }}
                    onClick={clickNext}
                    disabled={loading['course']}
                  >
                    Next
                  </Button>
                  {/* <Button
                    className="text-btn"
                    onClick={clickMonthlyReview}
                    disabled={loading['course']}
                  >
                    Monthly Review
                  </Button> */}
                </>
              )}
              {searchButtonRemote['generateReport'] && (
                <Button
                  className="text-btn"
                  onClick={clickGenerateReport}
                  disabled={loading['courseWork'] || loadingStudents}
                >
                  Generate Report
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
      {showReport && (
        <section className="classroom-coursework">
          <div className="classroom-coursework-wrapper">
            <div className="top-bar">
              <SelectMenu
                selectOptions={computedSelectedCourse}
                handleSelectItemClick={classroomSelectMenuHandler}
                isLoading={loadingStudents}
              />
              <div className="popup-menu-btn-wrapper">
                <PopupMenuButton
                  handleMenuItemsClick={classroomPopupMenuHandler}
                  menuItems={classroomPopupMenuItems}
                />
              </div>
            </div>
            <div className="courseworks">
              {!loadingStudents &&
              currentCourseWorks.some((c) => currentCourse.value === c.courseId)
                ? currentCourseWorks.map((cw, index) => {
                    return (
                      cw.courseId === currentCourse.value && (
                        <DisplayCard
                          key={index}
                          overlayTitle={cw.title}
                          onClick={(event) => handleCourseWorkClick(event, cw)}
                        />
                      )
                    );
                  })
                : !loadingStudents && (
                    <DisplayCard overlayTitle="No Course Work Items" />
                  )}
            </div>
          </div>
        </section>
      )}

      {showCourseWork['isShown'] && (
        <CourseworkCard data={showCourseWork} setIsShown={setShowCourseWork} />
      )}
    </div>
  );
}
