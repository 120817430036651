import React from 'react';
import { Redirect } from 'react-router-dom';
import Sidebar from '../components/SideBar';
import { useUser } from '../context/UserContext';
import ListIcon from '../assets/list.svg';
// import UserIcon from '../assets/user-icon.png';
export default function SideBarLayout({ children, location, ...props }) {
  const { currentUser } = useUser();
  return currentUser != null ? (
    <div className="side-bar-layout" {...props}>
      <Sidebar profileImg={currentUser.pictureUrl}>
        <span className="side-bar-item">
          <img src={ListIcon} alt="Submission" />
          Submission
        </span>
      </Sidebar>
      <main className="children-wrapper">{children}</main>
    </div>
  ) : (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location }
      }}
    />
  );
}
