import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import './styles.scss';
// Components
import Checkbox from '../Checkbox';
import Spinner from '../Spinner';
// Images
import ExpandMore from '../../assets/expand-more.svg';
// import Cross from '../../assets/cross.svg';

// Functions
const toggleExpand = (e) => {
  const ele = $(e.target)

  ele.parents('.autocomplete').toggleClass('active')
    .find('.search-options').toggleClass('active')
}

const clickAutocomplete = (e) => {
  const ele = $(e.target)

  if (!ele.is('.icon') && !ele.is('.icon img')) {

    ele.parents('.autocomplete').addClass('active')
      .find('.search-options').addClass('active')
  }
}

export default function Autocomplete({ options, seacrhFieldLabel, selectedOptions, setSelectedOptions, isLoading }) {
  const checkBoxSetStateExceptAll = useRef([])
  const optionAllCheckBoxSetState = useRef({})
  const [search, setSearch] = useState('')
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleSearchFieldChange = (e) => {
    const value = e.target.value
    setFilteredOptions(options.filter((option) => option['label'].toLowerCase().includes(value.toLowerCase())))
    setSearch(value)
  }

  const handleCheckboxChange = useCallback((optionValue, labelText, inputRef) => {
    const isInputChecked = inputRef.current.checked
    if (!isInputChecked) {
      setSelectedOptions(prev => {
        return [...prev.filter((option) => option['label'] !== labelText)]
      })
    } else {
      if (labelText !== 'All') {
        optionAllCheckBoxSetState.current.setIsChecked(false)
        setSelectedOptions(prev => {
          return [...prev.filter(option => option['label'] !== 'All'), { value: optionValue, label: labelText }]
        })
      } else {
        setSelectedOptions([{ value: optionValue, label: labelText }])
        checkBoxSetStateExceptAll.current.forEach(setIsChecked => setIsChecked(false))
      }
    }
  }, [setSelectedOptions])

  // props to child
  const getCheckboxSetState = useCallback(({ labelText, setIsChecked, inputRef }) => {
    if (labelText === 'All') {
      optionAllCheckBoxSetState.current = { setIsChecked, inputRef }
      // setSelectedOptions([{ value: labelText, label: labelText }])
    } else {
      checkBoxSetStateExceptAll.current = [...checkBoxSetStateExceptAll.current, setIsChecked]
    }
  }, [])

  // const clearInput = () => {
  //   setSearch('')
  //   setSelectedOptions([{ value: 'All', label: 'All' }])
  //   checkBoxSetStateExceptAll.current.forEach(setIsChecked => setIsChecked(false))
  //   optionAllCheckBoxSetState.current.setIsChecked(true)
  // }

  useEffect(() => {
    setFilteredOptions([{ value: 'All', label: 'All' }, ...options])
  }, [options])

  useEffect(() => {
    const callback = (e) => {
      const ele = $(e.target)
      if (!ele.parents('.autocomplete').length > 0) {
        $('.autocomplete').removeClass('active')
          .find('.search-options').removeClass('active')
      }
    }
    const bodyListener = $('body').on('click', callback)
    return () => {
      $('body').off('click', bodyListener)
    }
  }, [])

  return (
    <div className='autocomplete' onClick={clickAutocomplete}>
      <div className='search-field'>
        <div className='selected-options'>
          {selectedOptions.length === 1 && selectedOptions[0]['label'] === 'All'
            ? <span>All</span>
            : <span>{selectedOptions.length > 99 ? '99+' : selectedOptions.length}</span>}
        </div>
        <div className='text-field-wrapper'>
          <input type='text'
            placeholder={seacrhFieldLabel}
            value={search}
            onChange={handleSearchFieldChange}
            disabled={isLoading ? true : false} />
        </div>
        {/* {search &&
          <div className='icon cross' onClick={() => {
            setSearch('')
            setFilteredOptions(options)
          }}>
            <img src={Cross} alt='expand more options' />
          </div>
        } */}
        {isLoading ?

          <Spinner />
          : <div className='icon' onClick={toggleExpand}>

            <img src={ExpandMore} alt='expand more options' />
          </div>
        }
      </div>
      {!isLoading &&
        <div className='search-options'>
          {filteredOptions && filteredOptions.map((option, index) => (
            <Checkbox
              key={JSON.stringify(option) + index}
              optionValue={option.value}
              labelText={option.label}
              handleCheckboxChange={handleCheckboxChange}
              getCheckboxSetState={getCheckboxSetState}
              defaultChecked={selectedOptions.some(opt => opt.value === option.value)}
            />
          ))}
        </div>
      }
    </div>
  )
}

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  seacrhFieldLabel: PropTypes.string,
  setSelectedOptions: PropTypes.func
}