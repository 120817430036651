export const mode = {
  DEV: 'DEV',
  PRD: 'PRD'
};
export const currentMode = mode.PRD;
export const COOKIE_REFRESH_TOKEN = 'cg-refresh-token';
export const LOCAL_STORAGE_USER = 'cg-user';
export const COOKIE_ACCESS_TOKEN = 'cg-access-token';

export const GOOGLE_SUBMISSION_STATUS = {
  SUBMISSION_STATE_UNSPECIFIED: 'SUBMISSION_STATE_UNSPECIFIED',
  NEW: 'NEW',
  CREATED: 'CREATED',
  TURNED_IN: 'TURNED_IN',
  RETURNED: 'RETURNED',
  RECLAIMED_BY_STUDENT: 'RECLAIMED_BY_STUDENT'
};

export const STATUS_INFO = {
  NOT_SUBMITTED: {
    name: 'NOT_SUBMITTED',
    color: '#E57575'
  },
  LATE: {
    name: 'LATE',
    color: '#E5B875'
  },
  ON_TIME: {
    name: 'ON_TIME',
    color: '#75E597'
  },
  RETURNED: {
    name: 'RETURNED',
    color: '#43A7B9'
  },
  RESET: {
    name: 'RESET',
    color: '#333'
  }
};
