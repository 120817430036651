import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useGoogleLogin } from 'react-google-login';
import './styles.scss';
import { COOKIE_REFRESH_TOKEN, COOKIE_ACCESS_TOKEN } from '../../constants';
import { fetchCourses } from '../../utils/googleApi';
// Context
import { useCourses } from '../../context/CourseContext';
// Components
import Button from '../../components/Button';
import GoogleIcon from '../../assets/google-icon.png';
import Snackbar from '../../components/Snackbar';
import Spinner from '../../components/Spinner';
import { useDispatchUser, userTypes, useUser } from '../../context/UserContext';
import {
  fetchRefreshToken,
  fetchUserInfo,
  revokeToken
} from '../../utils/googleApi';
import { addUser } from '../../firebase/utils';

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENTID;
const scope =
  'https://www.googleapis.com/auth/classroom.courses.readonly' +
  ' https://www.googleapis.com/auth/classroom.coursework.students.readonly' +
  ' https://www.googleapis.com/auth/classroom.rosters.readonly' +
  ' https://www.googleapis.com/auth/classroom.profile.photos';
const cookiePolicy = 'single_host_origin';
const responseType = 'code';
const accessType = 'offline';

export default function Login({ isLoading }) {
  const { setCourses, setLoading, clearCourses } = useCourses();
  const dispatchUser = useDispatchUser();
  const { currentUser } = useUser();
  const [message, setMessage] = useState('');
  // const { isLoading, setIsLoading } = useState(false);
  const onSuccess = async (resp) => {
    try {
      // setIsLoading(true);
      // sometimes the consent doesn't show up. Therefore, the token cannot be used to fetch courses
      const tokenResp = await fetchRefreshToken(resp.code);
      // console.log(tokenResp.data);
      if (
        !tokenResp.data['scope'].includes(
          'https://www.googleapis.com/auth/classroom.courses.readonly'
        )
      ) {
        await revokeToken(tokenResp.data.access_token);
        throw new Error('Not contains required scopes');
      }
      const accessToken = tokenResp.data['access_token'];
      const userResp = await fetchUserInfo(accessToken);
      const uid = userResp.data['id'];
      const email = userResp.data['email'];
      const displayName = userResp.data['name'];
      const firstName = userResp.data['given_name'];
      const lastName = userResp.data['family_name'];
      const pictureUrl = userResp.data['picture'];
      dispatchUser({
        type: userTypes.SET_CURRENT_USER,
        payload: {
          accessToken,
          uid,
          email,
          displayName,
          firstName,
          lastName,
          pictureUrl
        }
      });
      // firebase
      addUser(uid, {
        email,
        displayName,
        firstName,
        lastName,
        pictureUrl
      });
      Cookies.set(COOKIE_REFRESH_TOKEN, tokenResp.data['refresh_token'], {
        expires: 30
      });
      Cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {
        expires: 1
      });
      // console.log(tokenResp)
      // console.log(currentUser)
      clearCourses();
      setLoading((prev) => ({ ...prev, course: true }));
      const courses = await fetchCourses(accessToken);
      const coursesList = courses['data']['courses'].map((course) => ({
        id: course.id,
        name: course.name,
        ownerId: course.ownerId,
        creationTime: course.creationTime,
        updateTime: course.updateTime,
        courseState: course.courseState
      }));
      setCourses(coursesList);
      setLoading((prev) => ({ ...prev, course: false }));
    } catch (error) {
      // console.log(error);
      // console.log(currentUser);
      // setIsLoading(false);
      onFailure();
    }
  };
  const onFailure = async () => {
    clearCourses();
    currentUser && (await revokeToken(currentUser.accessToken));
    dispatchUser({
      type: userTypes.LOGOUT_USER
    });
    setMessage('Error happened! Please try again');
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    scope,
    cookiePolicy,
    responseType,
    accessType
  });

  return (
    <div className="login">
      {message !== '' && <Snackbar message={message} setMessage={setMessage} />}
      {isLoading ? (
        <Spinner />
      ) : (
        <Button onClick={signIn}>
          <img src={GoogleIcon} alt="google" />
          <span>Login with Google</span>
        </Button>
      )}
    </div>
  );
}
