import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { firebaseConfig } from './config';
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ propmt: 'select_account' });
googleProvider.addScope('profile');
googleProvider.addScope('email');
// googleProvider.addScope(
//   'https://www.googleapis.com/auth/classroom.courses.readonly'
// );
// googleProvider.setCustomParameters('access_type')

export const addUser = async (uid, userData) => {
  try {
    if (fetchUserFromFB(uid) === null) {
      await db.collection('users').doc(uid).set(userData);
      console.log('added successfully');
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchUserFromFB = async (uid) => {
  const userRef = db.collection('users').doc(uid);
  const userDoc = await userRef.get();

  if (userDoc.exists) {
    return userDoc.data();
  }
  return null;
};
