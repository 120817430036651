import React, { useEffect } from 'react';
import './styles.scss';
import Cross from '../../assets/cross.svg';
import InfoCircle from '../../assets/info-circle.svg';
export default function Snackbar({ message, setMessage }) {
  const closeSnackbar = () => {
    const ele = document.querySelector('.snackbar');
    if (ele) {
      ele.classList.remove('active');
      setMessage('');
    }
  };
  useEffect(() => {
    document.querySelector('.snackbar').classList.add('active');
    const autoRemove = setInterval(() => {
      closeSnackbar();
    }, 2000);
    return () => {
      clearInterval(autoRemove);
      closeSnackbar();
    };
    // eslint-disable-next-line
  }, []);
  return (
    <div className="snackbar">
      <h6>
        <img src={InfoCircle} alt="Important Information" />
        <span>{message}</span>
        <img onClick={closeSnackbar} src={Cross} alt="Remove the message" />
      </h6>
    </div>
  );
}
